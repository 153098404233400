import "./App.css";
import { Component } from "react";
//import Web3 from "web3";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { account: "" };
  }

  async connect() {
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then((r) => this.setState({ account: r[0] }))
      .catch((err) => {
        if (err.code === 4001) {
          // EIP-1193 userRejectedRequest error
          // If this happens, the user rejected the connection request.
          console.log("Please connect to MetaMask.");
        } else {
          console.error(err);
        }
      });
  }

  render() {
    return (
      <div className="container">
        <h1>Hello Mr./Mrs.</h1>
        <p>Address: {this.state.account}</p>
        <button onClick={this.connect()}>Wallet Connect</button>
      </div>
    );
  }
}

export default App;
